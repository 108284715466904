.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.mainPageBackground {
  background-color: rgb(250, 250, 250);
  overflow:hidden;
  min-height: 100vh;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Dropdown Button */
.dropbtn {
  background-color: #0A4182;
  color: white;
  vertical-align: baseline;
  
  font-size: 16px;
  border: none;
  padding-top: 23px;
  padding-bottom: 23px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;  
  /*float: left;*/
  /*overflow:hidden;*/
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  width:100%;
  display: none;
  position: absolute;
  padding-left: 1REM;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999999;
  color: white;
  /*background-color: #0A4182;*/
  /*background-color:#279593;*/
  /*background-color:linear-gradient(to right, #279593, #489594);*/
  background:linear-gradient(to right, #279593, #48AF68);
  /*background-color:linear-gradient(to right,yellow, red);*/
}

/* Links inside the dropdown */
.dropdown-content a {
 /* position:relative;*/
 width:100%;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  /*background-color: rgb(10, 50, 99);*/
  background-color:#077573;
  color: white;
  transition: 0.5s;

}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

.dropdown:click .dropdown-content {display: none;}

/* Change the background color of the dropdown button when the dropdown content is shown */
/*.dropdown:hover .dropbtn {background-color: rgb(10, 50, 99);}*/
.dropdown:hover .dropbtn {background-color: #279593;}

/* TORCH specific css */
/* Dropdown Button */
.dropbtnTorch {
  background-color: #0A4182;
  color: white;
  vertical-align: baseline;
  
  font-size: 16px;
  border: none;
  padding-top: 23px;
  padding-bottom: 23px;
}
.dropbtnTorch:hover {
  color: rgb(172, 46, 1)
}

/* The container <div> - needed to position the dropdownTorch content */
.dropdownTorch {
  position: relative;
  display: inline-block;  
  /*float: left;*/
  /*overflow:hidden;*/
}

/* dropdownTorch Content (Hidden by Default) */
.dropdownTorch-content {
  width:100%;
  display: none;
  position: absolute;
  padding-left: 1REM;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999999;
  color: white;
  /*background-color: #0A4182;*/
  /*background-color:#279593;*/
  /*background-color:linear-gradient(to right, #279593, #489594);*/
  background:linear-gradient(to right, #FC4E31, #EE9D4A);
  /*background-color:linear-gradient(to right,yellow, red);*/
}
/* Links inside the dropdownTorch */
.dropdownTorch-content a {
 /* position:relative;*/
 width:100%;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdownTorch links on hover */
.dropdownTorch-content a:hover {
  /*background-color: rgb(10, 50, 99);*/
  background-color:#077573;
  color: white;
  transition: 0.5s;

}

/* Show the dropdownTorch menu on hover */
.dropdownTorch:hover .dropdownTorch-content {display: block;}

.dropdownTorch:click .dropdownTorch-content {display: none;}

/* Change the background color of the dropdownTorch button when the dropdownTorch content is shown */
/*.dropdownTorch:hover .dropbtnTorch {background-color: rgb(10, 50, 99);}*/
.dropdownTorch:hover .dropbtnTorch {background-color: #0b0b8a;}

.torchNavigatorHeaderLink {
  position: relative;
  display: inline-block;  
  color: #ffffff;
  text-decoration: "none";
  font-size: 14px;
  padding-right: 2REM;
}
.torchNavigatorHeaderLink:hover {
  color: rgb(172, 46, 1)
}
.torchHomeMenuIcon {
  margin-right: 2px;
}
.torchHomeMenuIcon:hover {
  color: rgb(172, 46, 1)
}