/* This styling is mainly useful for the summary cards */

.nsCardHeader {
  text-align: center;
  background-color: rgba(42, 181, 138, 1);
}

.nsRight {
  float: right;
}

.nsVer {
  padding-left: 2em;
}

.nsLink {
  color: black;
}

/* width */
::-webkit-scrollbar {
  width: 0.6em;
  height: 0.3em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e7e3e3ea;
}

/* Scrollbar universal CSS
  Changing to default black for now since Torch and FHT use same CSS file
  Difficult to give each site type its own scrollbar colour */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0) !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0) !important;
}

.nsScrollBox {
  height: 100px;
  width: auto;
  overflow: auto;
  background: whitesmoke;
  font: 100%/1.4 serif;
  border: 1px solid rgba(0, 0, 0, 0.25);
  white-space: nowrap;
}

.nsTallScrollBox {
  height: 250px;
  width: auto;
  overflow: auto;
  background: whitesmoke;
  font: 100%/1.4 serif;
  border: 1px solid rgba(0, 0, 0, 0.25);
  white-space: nowrap;
}

/* Scroll box height will be different dependent on purpose */
.nsExtraTallScrollBox {
  height: 700px;
  width: auto;
  overflow: auto;
  background: "whitesmoke";
  font: 100%/1.4 serif;
  border: 1px solid rgba(0, 0, 0, 0.25);
  white-space: nowrap;
}

/* nsExtraTallScrollBoxConfigEdit ensures height for ConfigEdit pages matches the select site components */
.nsExtraTallScrollBoxConfigEdit {
  height: 40.3REM;
  width: auto;
  overflow: auto;
  background: whitesmoke;
  font: 100%/1.4 serif;
  border: 1px solid rgba(0, 0, 0, 0.25);
  white-space: nowrap;
}

a:link {
  text-decoration: none;
  /* color: black; */
}

a:visited {
  text-decoration: none;
  /* color: black; */
}

a:hover {
  color: green;
}

.green {
  color: green;
}

/* div.nsMT:hover {
  overflow: visible;
} */
